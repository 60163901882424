/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { BASE_PATH, ColloBean, Configuration, FornituraBean, InputStreamResource } from 'app/api-friday';
import { CustomHttpUrlEncodingCodec } from 'app/api-friday/encoder';

@Injectable()
export class DownloadControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
    * downloadNotaFornitura
    *
    * @param idFornitura idFornitura
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public downloadNotaFornituraUsingGet(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadNotaFornituraUsingGet(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadNotaFornituraUsingGet(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadNotaFornituraUsingGet(idFornitura: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling downloadNotaFornituraUsingGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (idFornitura !== undefined) {
            queryParameters = queryParameters.set('idFornitura', <any>idFornitura);
        }

        return this.httpClient.get(`${this.basePath}/api/ailime/collo/downloadNotaMagazzino?idFornitura=` + idFornitura, { responseType: 'blob' });

    }

    /**
    * downloadTessiliFornitura
    *
    * @param idF2s idF2s
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
     public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
     public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
     public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
     public downloadTessiliF2sUsingGET(idF2s: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
         if (idF2s === null || idF2s === undefined) {
             throw new Error('Required parameter idF2s was null or undefined when calling downloadTessiliF2sUsingGET.');
         }
 
         let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
         if (idF2s !== undefined) {
             queryParameters = queryParameters.set('idF2s', <any>idF2s);
         }
 
         return this.httpClient.get(`${this.basePath}/api/ailime/collo/downloadTessiliF2s?idF2s=` + idF2s, { responseType: 'blob' });
 
     }

    


    /**
      * downloadEtichetteAdesiveFornitura
      * 
      * @param idFornitura idFornitura
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling downloadEtichetteAdesiveFornituraUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/api/ibim/magazzino/downloadEtichetteAdesiveFornitura`,
            idFornitura,
            {
                responseType: 'blob'
            }
        );
    }

    /**
     * download segnacolli brt zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public downloadSegnacolliBrtZplUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
     public downloadSegnacolliBrtZplUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
     public downloadSegnacolliBrtZplUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
     public downloadSegnacolliBrtZplUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/pdf'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get(`${this.basePath}/api/ailime/download/downloadSegnacolliBrtZpl`, { responseType: 'blob' });

    }

    /**
     * download segnacolli gls zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadSegnacolliGlsZplUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadSegnacolliGlsZplUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadSegnacolliGlsZplUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadSegnacolliGlsZplUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
        let headers = this.defaultHeaders;
 
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
 
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
 
        return this.httpClient.get(`${this.basePath}/api/ailime/download/downloadSegnacolliGlsZpl`, { responseType: 'blob' });

    }


    /**
     * download segnacolli zpl generic
     * @param collo colloBean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadSegnacolloZplUsingGET(collo: ColloBean, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadSegnacolloZplUsingGET(collo: ColloBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadSegnacolloZplUsingGET(collo: ColloBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadSegnacolloZplUsingGET(collo: ColloBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post(`${this.basePath}/api/ailime/download/downloadSegnacolloZpl`, collo, { responseType: 'blob' });

   }

    /**
     * download segnacolli brt zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFeedLeroyMerlinUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadFeedLeroyMerlinUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadFeedLeroyMerlinUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadFeedLeroyMerlinUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
        let headers = this.defaultHeaders;
 
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
 
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
 
        return this.httpClient.get(`${this.basePath}/api/ailime/download/downloadFeedLeroyMerlin`, { responseType: 'blob' });

    }

    /**
     * download segnacolli brt zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
        let headers = this.defaultHeaders;
  
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
  
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
  
        return this.httpClient.get(`${this.basePath}/api/ailime/download/downloadFeedLeroyMerlinOfferte`, { responseType: 'blob' });
 
    }

    /**
     * Stampa accessori BRT
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idCollo !== undefined && idCollo !== null) {
            queryParameters = queryParameters.set('idCollo', <any>idCollo);
        }
        console.log("id collo è "+idCollo);
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/api/ailime/download/stampaAccessoriBRT`, {params: queryParameters, responseType: 'blob' });

    }

        /**
     * Stampa accessori GLS
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
    
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
            if (idCollo !== undefined && idCollo !== null) {
                queryParameters = queryParameters.set('idCollo', <any>idCollo);
            }
            console.log("id collo è "+idCollo);
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.get(`${this.basePath}/api/ailime/download/stampaAccessoriGLS`, {params: queryParameters, responseType: 'blob' });
    
        }

                    /**
     * Stampa etichette tessili giornaliere
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        return this.httpClient.get(`${this.basePath}/api/ailime/download/stampaEtichetteTessiliGiornaliere`, { responseType: 'blob' });
    }

    

    /**
     * download note produzione materassi
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadNoteProduzioneMaterassiUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadNoteProduzioneMaterassiUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadNoteProduzioneMaterassiUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadNoteProduzioneMaterassiUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/api/ailime/download/downloadNoteProduzioneMaterassi`, { responseType: 'blob' });

   }

    /**
     * download etichette adesive trasferiti
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public printEtichetteAdesiveTrasferitiUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
     public printEtichetteAdesiveTrasferitiUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
     public printEtichetteAdesiveTrasferitiUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
     public printEtichetteAdesiveTrasferitiUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/pdf'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get(`${this.basePath}/api/ailime/download/printEtichetteAdesiveTrasferiti`, { responseType: 'blob' });

    }


     /**
     * download etichette adesive fornitura
     * 
     * @param idFornitura2stock idFornitura2stock
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printEtichetteAdesiveFornituraUsingGET(idFornitura2stock: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public printEtichetteAdesiveFornituraUsingGET(idFornitura2stock: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public printEtichetteAdesiveFornituraUsingGET(idFornitura2stock: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public printEtichetteAdesiveFornituraUsingGET(idFornitura2stock: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idFornitura2stock === null || idFornitura2stock === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling printEtichetteAdesiveFornituraUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idFornitura2stock !== undefined && idFornitura2stock !== null) {
            queryParameters = queryParameters.set('idFornitura2stock', <any>idFornitura2stock);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/api/ailime/download/printEtichetteAdesiveFornitura`, { params: queryParameters, responseType: 'blob' });

    }

}
